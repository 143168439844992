import React, { useEffect, useRef } from "react";
//= Scripts
import navbarScrollEffect from "common/navbarScrollEffect";
//= Layout
import MainLayout from "layouts/Main";
//= Components
import TopNav from "components/Navbars/TopNav-7";
import SideMenu from "components/ITCreative/SideMenu";
import Navbar from "components/Navbars/ITCreativeNav";
import Header from "components/ITCreative/Header";
import Community from "components/Saas/Community";
import About from "components/Saas/About";
import Clients from "components/Saas/Clients";
import Features from "components/Saas/Features";

import Testimonials from "components/Saas/Testimonials";
import Download from "components/Saas/Download";
import Footer from "components/Saas/Footer";
import Featuress from "components/App/Features";
import FAQ from "components/App/FAQ";
import Projects from 'components/ITCreative/Projects';

const HomeSaasTechnology = () => {
  const navbarRef = useRef(null);

  useEffect(() => {
    navbarScrollEffect(navbarRef.current);
  }, [navbarRef]);

  return (
    <MainLayout>
      <TopNav />
      <SideMenu />
      <Navbar />
      <Header />
      <main>
        <Featuress />
        <Community />
        <About />
        <Clients />
        <Features />
        <Projects />
        <FAQ />
        <Testimonials />
        <Download />
      </main>
      <Footer />
    </MainLayout>
  );
};

export const Head = () => {
  return (
    <>
      <title>Mutual Trust MFBank Ltd</title>
      <link rel="stylesheet" href="/assets/css/lib/bootstrap.min.css" />
      <link rel="stylesheet" href="/assets/css/style.css" />
    </>
  );
};

export default HomeSaasTechnology;
