import React from 'react';
import { Link } from 'gatsby';

const Content = ({ list, rtl }) => {
  return (
    <div className="content">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-8">
            <div className="img main-img2">
              <img src="/assets/img/about/about_s5_2_1.png" alt="" />
              <img src="/assets/img/about/about_s5_2_2.png" alt="" className="img-body" />
            </div>
          </div>
          <div className="col-lg-4">
            <div className="section-head mb-30 style-5">
              <h2>{ rtl ? '' : 'Internet ' } <span>{ rtl ? '' : 'Banking' }</span> </h2>
            </div>
            <p>
              { rtl ? '' : `Enjoy the convenience of banking on your own terms, wherever and whenever. Get real-time balances on your account(s), view and download your account activity.` }
            </p>
            <ul className="list-icon">
              {
                list.map((item, index) => (
                  <li key={index}>
                    <span className="icon">
                      <i className={item.icon}></i>
                    </span>
                    <h6>{ item.title }</h6>
                  </li>
                ))
              }
            </ul>
            <Link to="/page-contact-5" className="btn rounded-pill blue5-3Dbutn hover-blue2 sm-butn fw-bold mt-50">
              <span>{ rtl ? '  ' : 'Get Started' }</span>
            </Link>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Content